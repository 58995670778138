import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['HeaderVehicleWidget'].includes(w.name)),
    {
      name: 'VehicleLabel',
      type: 'VehicleWidget',
      location: '#cm-ymm-label',
      template: 'fitmentSearch/headerVehicleLabel',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      isAlwaysColumnLayout: true,
    },
  ],
};
